import Vue from "vue";
import Vuex from "vuex";
import auth from "./auth";
import alert from "./alert";
// import createPersistedState from "vuex-persistedstate";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    errors: false,
    publicData: [],
    mediaId: null,
    platform: [],
    type: [],
    dateRanges: [],
    search: "",
    page: 1,
    off_page: 1,

    //headerBreadcrumb
    headerBreadcrumb: null,

    //set list-view for post page
    cardView: true,
    rowView: false,
  },

  getters: {
    errors: (state) => {
      return state.errors;
    },
    getMediaId(state) {
      return state.mediaId;
    },
    getPlatform(state) {
      return state.platform;
    },
    getType(state) {
      return state.type;
    },
    getSearch(state) {
      return state.search;
    },
    getDateRanges(state) {
      return state.dateRanges;
    },
    getPage(state) {
      return state.page;
    },
    getOffPage(state) {
      return state.off_page;
    },
  },

  mutations: {
    SET_ERROR(state, errors) {
      state.errors = errors;
    },
    SET_MEDIA_ID(state, payload) {
      state.mediaId = payload;
    },
    SET_PLATFORM(state, payload) {
      state.platform = payload;
    },
    SET_TYPE(state, payload) {
      state.type = payload;
    },
    SET_SEARCH(state, payload) {
      state.search = payload;
    },
    SET_DATERANGES(state, payload) {
      state.dateRanges = payload;
    },
    SET_PAGE(state, payload) {
      state.page = payload;
    },
    SET_OFF_PAGE(state, payload) {
      state.off_page = payload;
    },

    //headerBreadcrumb
    SET_BREADCRUMB(state, payload) {
      state.headerBreadcrumb = payload;
    },
    RESET_BREADCRUMB(state) {
      state.headerBreadcrumb = null;
    },

    //set list-view for post page
    SET_CARD_VIEW(state) {
      state.cardView = true;
      state.rowView = false;
    },
    SET_ROW_VIEW(state) {
      state.cardView = false;
      state.rowView = true;
    },
  },

  actions: {
    setError({ commit }, errors) {
      commit("SET_ERROR", errors);
    },
  },

  modules: {
    auth,
    alert,
  },

  plugins: [
    //To keep Vuex state after refreshing
    // createPersistedState({
    //   paths: ["cardView", "rowView"],
    // }),
  ],
});
