import Vue from "vue";
import App from "@/App.vue";
import router from "@/router/router";
import store from "@/store";
import axios from "axios";
import moment from "moment";
import vueCookies from "vue-cookies";
import vuetify from "./plugins/vuetify";
Vue.use(vueCookies);
require("@/store/subscriber");
// 필요할 경우 쿠키 만료기간 세팅
// Vue.$cookies.config("7d");

Vue.config.productionTip = false;

Object.defineProperty(Vue.prototype, "$moment", { value: moment });

window.axios = require("axios");
Vue.prototype.axios = window.axios;
axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
axios.defaults.headers.common["Accept"] = "application/json";
axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
axios.defaults.headers.common["Content-Type"] = "application/json";
axios.defaults.baseURL = process.env.VUE_APP_AXIOS_BASE_URL;
axios.defaults.headers.common["Authorization"] =
  "Bearer " + localStorage.getItem("token");
axios.defaults.headers.common["role"] = "media";

axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response.status === 500) {
      store.dispatch("auth/logout");
      router.push({
        name: "Login",
      });
      store.commit("alert/showAlertMsg", {
        message: "일시적인 장애로 잠시 후 이용해 주십시오.",
        type: "alert_error",
      });
    }
    if (error.response.status === 401) {
      store.dispatch("auth/logout");
      router.push({
        name: "Login",
      });
    }
    return Promise.reject(error);
  }
);

store.dispatch("auth/attempt", localStorage.getItem("token")).then(() => {
  new Vue({
    router,
    store,
    vuetify,
    render: (h) => h(App),
  }).$mount("#app");
});
