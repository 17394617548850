<template>
  <div id="app">
    <div class="wrap" :class="{ headerActive: menu }">
      <header v-if="authenticated" :class="{ headerActive: menu }">
        <div class="header_container">
          <h1 class="logo">
            <router-link to="/"
              ><img src="@/assets/images/logo_media.svg" alt="메타베이 로고"
            /></router-link>
          </h1>

          <nav>
            <ul class="gnb_container">
              <li
                v-for="(item, index) in gnbItems"
                :key="index"
                @click="menu = false"
              >
                <router-link :to="item.path">
                  <div
                    class="gnb_item"
                    :class="{
                      active: getMenuActiveFlag(item),
                    }"
                  >
                    <span class="icon"></span>
                    <p class="title">{{ item.title }}</p>
                  </div>
                </router-link>
                <ul class="gnb" v-if="item.menus">
                  <li
                    v-for="(menu, i) in item.menus"
                    :key="i"
                    :class="{
                      active: getMenuActiveFlag(menu),
                    }"
                  >
                    <router-link :to="menu.path">{{ menu.title }}</router-link>
                  </li>
                </ul>
              </li>
            </ul>
          </nav>
          <!-- <nav>
            <ul
              class="gnb_container"
              v-for="(item, index) in gnbItems"
              :key="index"
            >
              <li @click="menu = false">
                <p class="gnb_caption">{{ item.subheader }}</p>
                <ul class="gnb" v-for="(menu, i) in item.menus" :key="i">
                  <li
                    :class="{
                      active: getMenuActiveFlag(menu),
                    }"
                  >
                    <router-link :to="menu.path">
                      <span></span>
                      {{ menu.title }}
                    </router-link>
                  </li>
                </ul>
              </li>
            </ul>
          </nav> -->
        </div>

        <!-- <div class="cs_wrap">
          <div class="cs_container">
            <img src="@/assets/images/cs_illust.svg" alt="문의하기" />
            <p>도움이 필요하신가요?</p>
            <span
              >어려움이 있으시다면 언제든지<br />
              1:1 문의주세요!</span
            >
            <a :href="`mailto:${inquiryEmail}`" class="inquiry_btn primary_btn"
              >문의하기</a
            >
          </div>
        </div> -->
      </header>

      <div v-if="authenticated" class="snb">
        <div class="snb_container">
          <div class="snb_title_wrap">
            <p
              class="snb_title"
              :class="{ inactive: $store.state.headerBreadcrumb }"
            >
              {{ title }}
            </p>
            <p class="detail_title" v-if="detailTitle">
              {{ detailTitle }}
            </p>
            <!-- <p class="detail_title" v-if="$store.state.headerBreadcrumb">
              {{ $store.state.headerBreadcrumb }}
            </p> -->
          </div>

          <div class="user_info_wrap">
            <!-- <button
              class="alarm_btn"
              :class="{ on: alarm }"
              @click="alarm = !alarm"
            ></button> -->

            <div class="user_info">
              <div class="user_avatar">
                <img :src="userInfo.avatarImg" alt="유저 아바타" />
              </div>
              <div class="user_dropdown_wrap" @click="userOption = !userOption">
                <span class="user_name">{{ userInfo.name }}</span>
                <button
                  class="dropdown_btn"
                  :class="{ active: userOption }"
                ></button>
              </div>

              <ul
                class="user_option dropdown_list"
                :class="{ active: userOption }"
              >
                <li @click="logout">로그아웃</li>
              </ul>
            </div>
          </div>

          <h1 class="logo">
            <router-link to="/"
              ><img src="@/assets/images/logo_media.svg" alt="메타베이 로고"
            /></router-link>
          </h1>

          <div class="sub_btn_wrap">
            <button
              class="user_info_btn"
              @click="userOption = !userOption"
            ></button>
            <button
              :class="{ headerActive: menu }"
              class="snb_toggle_btn"
              @click="menu = !menu"
            ></button>
          </div>
          <ul
            class="user_mb_option dropdown_list"
            :class="{ active: userOption }"
          >
            <li>
              <div class="user_avatar">
                <img :src="userInfo.avatarImg" alt="유저 아바타" />
              </div>
              <span class="user_name">{{ userInfo.name }}</span>
            </li>
            <li @click="logout">로그아웃</li>
          </ul>
        </div>
      </div>

      <router-view :key="$route.fullPath" />

      <!-- <footer v-if="authenticated" :class="{ headerActive: menu }">
        <div class="container_footer">
          <ul class="fnb">
            <li
              v-for="(item, index) in fnbItems"
              :key="index"
              :class="{ tos: item.title === '개인정보처리방침' ? true : false }"
            >
              <router-link :to="item.path">{{ item.title }}</router-link>
            </li>
          </ul>

          <small class="copy"
            >Copyright&copy;TDI Group. All Rights Reserved.</small
          >
        </div>
      </footer> -->

      <AlertMessage
        v-if="alertActive"
        :message="alertMessage"
        :type="alertType"
      />

      <ScrollMove v-if="authenticated" />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import AlertMessage from "@/components/common/AlertMessage.vue";
import ScrollMove from "@/components/common/ScrollMove.vue";

export default {
  name: "App",

  components: {
    AlertMessage,
    ScrollMove,
  },

  data() {
    return {
      userOption: false,
      alarm: false,
      menu: false,

      userInfo: {
        name: "",
        avatarImg: require("@/assets/images/icon_user_avatar.svg"),
      },

      path: "",
      title: "",
      detailTitle: "",

      mediaId: null,
      gnbItems: [
        {
          title: "대시보드",
          path: "/",
        },
        {
          title: "리포트",
          path: "/report",
        },
        {
          title: "정산",
          path: "/calculate",
        },
      ],

      fnbItems: [
        {
          title: "이용약관",
          path: "/use-terms",
        },
        {
          title: "개인정보처리방침",
          path: "/private-terms",
        },
        {
          title: "공지사항",
          path: "/notice",
        },
        {
          title: "고객센터",
          path: "/cs-center",
        },
      ],
      scrollY: window.scrollY,
      width: window.innerWidth,
    };
  },

  watch: {
    $route(to) {
      this.path = to.path;
      this.userOption = false;
      this.activateGnb();
    },

    user(v) {
      this.userInfo.name = v?.name;
    },
    // scrollY(v) {
    //   let totalY = document.body.scrollHeight;
    //   let windowY = window.innerHeight;
    //   let footerY = document.querySelector("footer").offsetHeight;
    //   // let cs = document.querySelector(".cs_wrap");
    //   let scrollTop = document.querySelector(".scroll_move_wrap");

    //   if (this.width > 1023) {
    //     if (v + footerY > totalY - windowY) {
    //       scrollTop.style.bottom = "90px";
    //       // cs.style.bottom = "90px";
    //     } else {
    //       scrollTop.style.bottom = "40px";
    //       // cs.style.bottom = "24px";
    //     }
    //   } else if (this.width < 1024) {
    //     if (v + footerY > totalY - windowY) {
    //       scrollTop.style.bottom = "85px";
    //     } else {
    //       scrollTop.style.bottom = "20px";
    //     }
    //   }
    // },
  },

  computed: {
    ...mapGetters({
      authenticated: "auth/authenticated",
      user: "auth/user",
      errors: "errors",

      //알럿
      alertActive: "alert/alertActive",
      alertMessage: "alert/alertMessage",
      alertType: "alert/alertType",
    }),
  },

  mounted() {
    this.updateAuthMe();
    window.addEventListener("scroll", () => {
      this.$nextTick(() => {
        this.scrollY = window.scrollY;
      });
    });
    window.addEventListener("resize", () => {
      this.$nextTick(() => {
        this.width = window.innerWidth;
      });
    });

    this.path = this.$router.history.current.path;
    this.activateGnb();
  },

  updated() {
    this.updateAuthMe();
    window.scrollTo(0, 0);
  },

  methods: {
    updateAuthMe() {
      this.$store.dispatch("auth/attempt", localStorage.getItem("token"));
    },

    activateGnb() {
      let gnbItems = this.gnbItems;
      this.title = "";
      this.detailTitle = "";

      for (let i = 0; gnbItems.length > i; i++) {
        if (gnbItems[i].path === this.path || gnbItems[i].deep === this.path) {
          this.title = gnbItems[i].title;
        }
        let menu = gnbItems[i].menus;
        for (let j = 0; menu?.length > j; j++) {
          if (menu[j].path === this.path) {
            this.detailTitle = menu[j].title;
          }
        }
      }
    },

    getMenuActiveFlag(menu) {
      return this.path === menu.path ? true : false;
    },

    ...mapActions({
      logoutAction: "auth/logout",
    }),

    logout() {
      this.resetStore();
      this.logoutAction().then(() => {
        this.$router.replace({
          name: "Login",
        });
      });
    },

    resetStore() {
      this.$store.commit("SET_PLATFORM", []);
      this.$store.commit("SET_TYPE", []);
      this.$store.commit("SET_SEARCH", "");
      this.$store.commit("SET_DATERANGES", []);
      this.$store.commit("SET_PAGE", 1);
      this.$store.commit("SET_OFF_PAGE", 1);
    },

    closeModal() {
      this.campaignModal = false;
    },
  },
};
</script>

<style>
@import url("@/assets/css/reset.css");
@import url("@/assets/css/common.css");
@import url("@/assets/css/media.css");
</style>
