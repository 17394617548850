<template>
  <div class="scroll_move_wrap" :class="{ active: active }">
    <button class="scroll_move_btn" @click="moveScroll()">
      <img src="@/assets/images/icon_scroll_arrow.svg" alt="스크롤 아이콘" />
    </button>
  </div>
</template>

<script>
export default {
  name: "ScrollMove",
  data() {
    return {
      scrollY: window.scrollY,
      active: false,
    };
  },
  watch: {
    scrollY(v) {
      if (v > 0) {
        this.active = true;
      } else {
        this.active = false;
      }
    },
  },
  mounted() {
    window.addEventListener("scroll", () => {
      this.$nextTick(() => {
        this.scrollY = window.scrollY;
      });
    });
  },
  methods: {
    moveScroll() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
  },
};
</script>
<style scoped>
/* scroll S */
.scroll_move_wrap {
  position: fixed;
  right: 40px;
  bottom: 40px;
  z-index: 9;
  opacity: 0;
  transition: opacity 0.2s ease-in-out, bottom 0.05s ease;
}
.scroll_move_wrap.active {
  opacity: 1;
}
.scroll_move_btn {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 54px;
  height: 54px;
  background-color: var(--color-indigo-600);
  border-radius: 50%;
  transition: background-color 0.3s;
  cursor: pointer;
}
.scroll_move_btn:hover {
  background-color: var(--color-indigo-700);
}
.scroll_move_btn img {
  transform: rotate(0deg);
}
.scroll_move_btn span {
  display: block;
  margin-top: 6px;
  color: #ffffff;
  font-weight: 500;
  font-size: 15px;
  line-height: 21px;
}
/* scroll E */
@media all and (max-width: 1023px) {
  .scroll_move_wrap {
    right: 20px;
    bottom: 20px;
  }
  .scroll_move_btn {
    width: 42px;
    height: 42px;
  }
  .scroll_move_btn img {
    width: 14px;
  }
}
</style>
