<template>
  <div class="alert_message_wrap">
    <div :class="['alert_success', type === 'alert_success' ? 'active' : '']">
      <span class="alert_icon"></span>
      <p>{{ message }}</p>
      <span class="close_alert" @click="closeAlert"></span>
    </div>

    <div :class="['alert_error', type === 'alert_error' ? 'active' : '']">
      <span class="alert_icon"></span>
      <p>{{ message }}</p>
      <span class="close_alert" @click="closeAlert"></span>
    </div>

    <div :class="['alert_warning', type === 'alert_warning' ? 'active' : '']">
      <span class="alert_icon"></span>
      <p>{{ message }}</p>
      <span class="close_alert" @click="closeAlert"></span>
    </div>

    <div :class="['alert_info', type === 'alert_info' ? 'active' : '']">
      <span class="alert_icon"></span>
      <p>{{ message }}</p>
      <span class="close_alert" @click="closeAlert"></span>
    </div>
  </div>
</template>

<script>
import { mapMutations } from "vuex";

export default {
  name: "AlertMessage",
  props: ["message", "type"],

  methods: {
    ...mapMutations({
      closeAlert: "alert/closeAlertMsg",
    }),
  },
};
</script>

<style></style>
