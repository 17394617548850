import axios from "axios";
import store from "@/store";

export default {
  namespaced: true,

  state: {
    token: null,
    user: null,
  },

  getters: {
    authenticated(state) {
      return state.token;
    },

    user(state) {
      return state.user;
    },
  },

  mutations: {
    SET_TOKEN(state, token) {
      state.token = token;
    },

    SET_USER(state, data) {
      state.user = data;
    },
  },

  actions: {
    async login({ dispatch }, payload) {
      await axios.post("api/v1/auth/login", payload).then((res) => {
        dispatch("attempt", res.data.access_token);
      });
      // .catch(() => {
      //   alert("로그인 정보를 확인해주세요.");
      // });
    },

    async attempt({ commit, state, dispatch }, token) {
      if (token) {
        commit("SET_TOKEN", token);
      }

      if (!state.token) {
        return;
      }

      await axios
        .get("api/v1/auth/me")
        .then((res) => {
          commit("SET_USER", res.data.user);
          localStorage.setItem("media_id", res.data.user.media_id);
        })
        .catch(() => {
          dispatch("logout");
        });
    },

    async logout({ commit }) {
      commit("SET_TOKEN", null);
      commit("SET_USER", null);
      localStorage.clear();
    },
  },
};
