import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      name: "Home",
      component: () => import("@/views/HomeView.vue"),
      beforeEnter: (to, from, next) => {
        if (!localStorage.getItem("token")) {
          return next({
            name: "Login",
          });
        }
        next();
      },
    },
    {
      path: "/login",
      name: "Login",
      component: () => import("@/views/LoginView.vue"),
      beforeEnter: (to, from, next) => {
        if (localStorage.getItem("token")) {
          return next({
            name: "Home",
          });
        }
        next();
      },
    },
    {
      path: "/report",
      name: "MediaReport",
      component: () => import("@/views/report/MediaReport.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/campaigns",
      name: "CampaignList",
      component: () => import("@/views/campaign/CampaignList.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/campaigns/campaign-show/:id",
      name: "CampaignShow",
      component: () => import("@/views/campaign/CampaignShow.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/medias",
      name: "MediaList",
      component: () => import("@/views/media/MediaList.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/medias/media-show/:id",
      name: "MediaShow",
      component: () => import("@/views/media/MediaShow.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/clients",
      name: "ClientLst",
      component: () => import("@/views/client/ClientList.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/calculate",
      name: "MediaCalculate",
      component: () => import("@/views/calculate/MediaCalculate.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/client-calculate",
      name: "ClientCalculate",
      component: () => import("@/views/calculate/ClientCalculate.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/media-calculate-show/:id",
      name: "MediaCalculateShow",
      component: () => import("@/views/calculate/MediaCalculateShow.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/client-calculate-show",
      name: "ClientCalculateShow",
      component: () => import("@/views/calculate/ClientCalculateShow.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/categories",
      name: "CategoryList",
      component: () => import("@/views/media/CategoryList.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/posts",
      name: "PostList",
      component: () => import("@/views/post/PostList.vue"),
      meta: {
        requiresAuth: true,
      },
    },
  ],
});

router.beforeEach((to, from, next) => {
  //headerBreadcrumb
  store.commit("RESET_BREADCRUMB");

  if (to.matched.some((route) => route.meta.requiresAuth)) {
    if (!localStorage.getItem("token")) {
      return next({
        name: "Login",
      });
    }
  }
  next();
});

export default router;
